module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"skypoint","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6InNreXBvaW50LWRldi1mZjg2YTNlYy1mY2YwLTQwZDAtYjA5YS1iZWRkZmJkMjEzMzRfNCIsImRhdGUiOjE2NjY2NjIzMzMsImRvbWFpbiI6InNreXBvaW50LWRldiIsImlhdCI6MTY2NjY2MjMzM30.8W_eNfiwSnrh0fDKpvVI0C7qsnDaMFa8O0xYmQuA-IQ","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6InNreXBvaW50LTYxNzBkMTA4LWIwZjYtNDRjNy04YWEzLTRmMzQ3Mzc3M2M5N180IiwiZGF0ZSI6MTY4NDcxMzUxMCwiZG9tYWluIjoic2t5cG9pbnQiLCJpYXQiOjE2ODQ3MTM1MTB9.wN4UsEAXf5JbTsf5VbEtLckp3ZQRefAFu5NgiSv8K6Q","promptForAccessToken":true,"apiEndpoint":"https://skypoint.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","background_color":"#0054a8","theme_color":"#0054a8","display":"standalone","icon":"./src/images/icon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d358df8ef7068c5e3d29681fa76d3112"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-135,"duration":300},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
